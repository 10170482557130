import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys } from "src/app/Models/Enums";
import { YesNo, CollectionPreference } from "src/app/Models/Enums";

@Component({
	selector: "ls-notice-of-assignment",
	templateUrl: "./notice-of-assignment.component.html",
	styleUrls: ["./notice-of-assignment.component.scss"]
})
export class NoticeOfAssignmentComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	public get YesNo() {
		return YesNo;
	}
	selectedFinanceDisclosure?: string;
	FINANCE_DISCLOSURE = "financeDisclosure";
	COLLECTION_PREFERENCE = "collectionPreference";

	noticeOfAssignmentForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;

	finyn: GenericLookup<string>[] = [];
	collections: GenericLookup<string>[] = [];

	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.CollectionPreference))
		])
			.pipe(
				filter(([finyn, cp]) => !!finyn && !!cp),
				takeUntil(this.componentTeardown$),
				map(([finyn, cp]) => {
					this.finyn = finyn!;
					this.collections = cp!;
				})
			)
			.subscribe();

		this.noticeOfAssignmentForm = new FormGroup({
			financeDisclosure: new FormControl(
				this.companyProfileRelationship?.financeDisclosureYesNoId,
				Validators.required
			),
			collectionPreference: new FormControl(
				this.companyProfileRelationship?.collectionPreferenceId,
				Validators.required
			)
		});

		if (this.companyProfileRelationship?.financeDisclosureYesNoId)
			this.handleFinancialDisclosureChange(this.companyProfileRelationship!.financeDisclosureYesNoId!);
		this.noticeOfAssignmentForm
			.get(this.FINANCE_DISCLOSURE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.handleFinancialDisclosureChange(v);
				})
			)
			.subscribe();
	}
	private handleFinancialDisclosureChange(value: string) {
		this.selectedFinanceDisclosure = value;
		if (this.selectedFinanceDisclosure === YesNo.NO) {
			this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.setValidators(Validators.required);
		} else {
			//do not validate the hidden control, db value will be set in getCompanyProfileRelationshipForUpdate
			this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.clearValidators();
			this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.setValue(null);
		}
	}
	isFormInvalid() {
		return this.noticeOfAssignmentForm.invalid;
	}
	getCompanyProfileRelationshipForUpdate() {
		const financeDisclosureId = this.noticeOfAssignmentForm.get(this.FINANCE_DISCLOSURE)?.value;
		let collectionPreferenceId = this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.value;

		if (financeDisclosureId === YesNo.YES || financeDisclosureId === YesNo.IDK) {
			collectionPreferenceId = CollectionPreference.INFORM;
		}
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

		updatedCompanyProfileRelationship.financeDisclosureYesNoId = financeDisclosureId;
		updatedCompanyProfileRelationship.collectionPreferenceId = collectionPreferenceId;
		return updatedCompanyProfileRelationship;
	}
	continue() {
		if (this.noticeOfAssignmentForm.dirty) {
			const updatedCompanyProfileRelationship = this.getCompanyProfileRelationshipForUpdate();

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
