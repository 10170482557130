import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { Component, OnInit } from "@angular/core";
import { CodatSelectors, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";
import { BankAccount, DisplayConfig, Layout } from "../../../../../Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-confirm-bank-account",
	templateUrl: "./confirm-bank-account.component.html",
	styleUrls: ["./confirm-bank-account.component.scss"]
})
export class ConfirmBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	public bankName?: string;
	public accountNumber?: string;
	public confirmIndex = 0;
	public changeIndex = 1;
	public isManualBankAccount = false;
	BANK_NAME_TOKEN = "!BANK_NAME!";
	ACCOUNT_NUMBER_TOKEN = "!ACCOUNT_NUMBER_TOKEN!";
	ALIAS_TOKEN = "!ALIAS!";

	constructor(
		public store: Store,
		public routeStepDataService: RouteStepDataService,
		private _codatSelectors: CodatSelectors,
		private _companyProfileSelectors: CompanyProfileSelectors
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this._codatSelectors.selectBankAccounts),
			this.store.select(this._companyProfileSelectors.selectCompanyProfile)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([ba, cp]) => !!cp),
				map(([bankAccounts, companyProfile]) => {
					this.setRoutingIndexes(companyProfile!.bankAccount);
					bankAccounts?.map((ba: DisplayedBankAccount) => {
						if (ba.name === companyProfile!.bankAccount?.alias) {
							this.bankName = ba.name;
							this.accountNumber = ba.accountNumber;
							this.replacementTokens.set(this.BANK_NAME_TOKEN, ba.institution);
							this.replacementTokens.set(this.ALIAS_TOKEN, ba.alias);
							this.replacementTokens.set(this.ACCOUNT_NUMBER_TOKEN, ba.accountNumber);
						}
					});
				})
			)
			.subscribe();
	}

	public setRoutingIndexes(bankAccount?: BankAccount): void {
		if (!bankAccount?.codatBankAccountId) {
			this.confirmIndex = 2;
			this.changeIndex = 3;
			this.isManualBankAccount = true;
		}
	}

	public onClick(index: number): void {
		let state = {};
		if (index === this.changeIndex && this.isManualBankAccount) {
			state = {
				bypassCodatGuard: true
			};
		}
		this.nav(index, undefined, undefined, state);
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Select your account", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							`You're already receiving payments to ‘${this.ALIAS_TOKEN}’ account in ‘${this.BANK_NAME_TOKEN}’`,
							TextStyle.BODY_EXTRA_EXTRA_LARGE_BOLD
						)
					],
					[
						"p2",
						new DisplayConfig(
							"Please confirm if you want to receive payments into this account again or if you'd like to change your account.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([
					["confirm", new DisplayConfig("Confirm", TextStyle.BUTTON_LARGE)],
					["change", new DisplayConfig("Change Account", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
