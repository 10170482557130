export enum TextStyle {
	HEADLINE_LARGE = "HEADLINE_LARGE",
	HEADLINE_MEDIUM = "HEADLINE_MEDIUM",
	HEADLINE_SMALL = "HEADLINE_SMALL",
	TITLE_LARGE = "TITLE_LARGE",
	TITLE_MEDIUM = "TITLE_MEDIUM",
	TITLE_SMALL = "TITLE_SMALL",
	LABEL_EXTRA_LARGE = "LABEL_EXTRA_LARGE",
	LABEL_LARGE = "LABEL_LARGE",
	LABEL_LARGE_BOLD = "LABEL_LARGE_BOLD",
	LABEL_MEDIUM = "LABEL_MEDIUM",
	LABEL_MEDIUM_CAPS = "LABEL_MEDIUM_CAPS",
	LABEL_MEDIUM_BOLD = "LABEL_MEDIUM_BOLD",
	LABEL_SMALL = "LABEL_SMALL",
	LABEL_SMALL_CAPS = "LABEL_SMALL_CAPS",
	LABEL_EXTRA_SMALL = "LABEL_EXTRA_SMALL",
	LABEL_EXTRA_SMALL_BOLD = "LABEL_EXTRA_SMALL_BOLD",
	LABEL_EXTRA_SMALL_BADGE = "LABEL_EXTRA_SMALL_BADGE",
	LABEL_EXTRA_EXTRA_SMALL = "LABEL_EXTRA_EXTRA_SMALL",
	BODY_EXTRA_LARGE = "BODY_EXTRA_LARGE",
	BODY_EXTRA_EXTRA_LARGE_BOLD = "BODY_EXTRA_EXTRA_LARGE_BOLD",
	BODY_LARGE = "BODY_LARGE",
	BODY_LARGE_BOLD = "BODY_LARGE_BOLD",
	BODY_MEDIUM = "BODY_MEDIUM",
	BODY_MEDIUM_SEMI_BOLD = "BODY_MEDIUM_SEMI_BOLD",
	BODY_SMALL = "BODY_SMALL",
	BODY_SMALL_SEMI_BOLD = "BODY_SMALL_SEMI_BOLD",
	BODY_EXTRA_SMALL = "BODY_EXTRA_SMALL",
	BUTTON_SMALL = "BUTTON_SMALL",
	BUTTON_LARGE = "BUTTON_LARGE"
}
