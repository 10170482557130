import { Component } from "@angular/core";
import { IRouteStepData } from "../Models/Interfaces";
import { Subject } from "rxjs";
import { Params } from "@angular/router";
import { RouteStepDataService } from "../Modules/COT-Module/Services";

@Component({
	selector: "ls-route-step-data-base",
	template: ""
})
/**
 * @class RouteStepDataBaseComponent should be used as the base for all onboarding screens.
 */
export abstract class RouteStepDataBaseComponent {
	public activeRouteData?: IRouteStepData;
	protected componentTeardown$ = new Subject();
	constructor(public routeStepDataService: RouteStepDataService) {
		this.activeRouteData = this.routeStepDataService.getActiveRouteData();
	}

	nav(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params, state?: any) {
		this.routeStepDataService.navByConfig(index, paramMap, queryParams, state);
	}
}
