<ng-container [ngSwitch]="displayConfig?.textStyle" *ngIf="displayConfig?.display">
	<!-- HEADER -->
	<h1 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_LARGE" [class]="'l '+extraClasses" [innerHTML]="displayConfig?.text"></h1>
	<h2 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_MEDIUM" [class]="'l '+extraClasses" [innerHTML]="displayConfig?.text"></h2>
	<h3 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_SMALL" [class]="extraClasses" [innerHTML]="displayConfig?.text"></h3>
	<!-- END HEADER -->

	<!-- TITLE -->
	<p [id]="id" *ngSwitchCase="TextStyle.TITLE_LARGE" [class]="'title l '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.TITLE_MEDIUM" [class]="'title m '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.TITLE_SMALL" [class]="'title s '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<!-- END TITLE -->
	
	<!-- LABEL -->
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_LARGE" [class]="'xl '+ extraClasses" [for]="forLabel" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_LARGE" [class]="'l '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_LARGE_BOLD" [class]="'l-bold '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM" [class]="'m '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM_BOLD" [class]="'m-bold '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM_CAPS" [class]="'m-caps '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_SMALL_CAPS" [class]="'s-caps '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_SMALL" [class]="'s '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL" [class]="'xs '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL_BOLD" [class]="'xs-bold '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL_BADGE" [class]="'xs-badge '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_EXTRA_SMALL" [class]="'xxs '+extraClasses" [innerHTML]="displayConfig?.text"></label>
	<!-- END LABEL -->
	
	<!-- BODY -->
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_EXTRA_LARGE" [class]="'xl '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_LARGE" [class]="'l '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_LARGE_BOLD" [class]="'l-bold '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_MEDIUM" [class]="'m '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_MEDIUM_SEMI_BOLD" [class]="'m-bold '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_SMALL" [class]="'s '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_SMALL_SEMI_BOLD" [class]="'s-bold '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<p [id]="id" *ngSwitchCase="TextStyle.BODY_EXTRA_SMALL" [class]="'xs '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	<!-- END BODY -->

	<!--DEFAULT-->
	<p [id]="id" *ngSwitchDefault [class]="'l '+extraClasses" [innerHTML]="displayConfig?.text"></p>
	
</ng-container>