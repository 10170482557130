import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CompanyDocument } from "../../Models";
import { FileUploadError, SortEvent } from "../../Models/Interfaces";
import { FileHandle } from "../../../Elements/upload-widget/FileHandle";
import { environment } from "../../../environments/environment";

@Component({
	selector: "ls-upload",
	templateUrl: "./upload.component.html",
	styleUrls: ["./upload.component.scss"]
})
export class UploadComponent {
	@Input() header?: string;
	@Input() description?: string;
	@Input() files?: CompanyDocument[];
	@Input() allowedFileExtensions?: string[];
	@Input() maxFileCount = 10;
	@Input() maxFileSize?: number;
	@Input() nextButtonDisabled = false;
	@Input() nextButtonText = "Next";
	@Input() disabled = false;
	@Input() submitted = false;
	@Output() nextClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() sortChanged: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
	@Output() openFileClicked: EventEmitter<CompanyDocument> = new EventEmitter<CompanyDocument>();
	@Output() deleteFileClicked: EventEmitter<CompanyDocument> = new EventEmitter<CompanyDocument>();
	@Output() uploadedFiles: EventEmitter<FileHandle[]> = new EventEmitter<FileHandle[]>();
	@Output() fileUploadError: EventEmitter<FileUploadError> = new EventEmitter<FileUploadError>();
	protected readonly environment = environment;

	onClick() {
		this.nextClicked.next(null);
	}

	public openFile(file: CompanyDocument) {
		this.openFileClicked.emit(file);
	}

	public deleteFile(file: CompanyDocument) {
		this.deleteFileClicked.emit(file);
	}

	public handleSortChange(sort: SortEvent) {
		this.sortChanged.emit(sort);
	}

	public emitFiles(files: FileHandle[]) {
		this.uploadedFiles.emit(files);
	}

	public emitError(error: FileUploadError) {
		this.fileUploadError.emit(error);
	}
}
