import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable } from "rxjs";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { CompanyProfile, DisplayConfig, HtmlContent, Layout, OnboardCompanyProgress } from "../../../../../Models";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-company-name",
	templateUrl: "./company-name.component.html",
	styleUrls: ["./company-name.component.scss"]
})
export class CompanyNameComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		private companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
		this.content = new HtmlContent({ layout: this.DefaultLayout });
	}

	LEGAL_NAME = "legalName";

	radioClicked = false;
	companyNameIsLegalName = false;
	confirmedLegalName = false;
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		]).pipe(
			filter(([cp, prog]) => !!cp && !!prog),
			map(([cp, prog]) => {
				this.companyProfile = cp;
				this.form = new FormGroup({
					legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required)
				});
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				this.handleYesNoState(cp!, prog!);
				this.replacementTokens.set(this.COMPANY_NAME_TOKEN, cp?.legalBusinessName ?? "");
				return cp;
			})
		);
	}

	public handleYesNoState(cp: CompanyProfile, prog: OnboardCompanyProgress) {
		const step = this.activeRouteData?.step ?? 0;
		const substep = this.activeRouteData?.substep ?? 0;
		if (
			cp.hasSubmittedApplications ||
			prog.currentStep! > step ||
			(prog.currentStep! === step && prog.currentSubStep! > substep)
		) {
			this.legalName(true);
			this.confirmedLegalName = true;
		}
	}

	legalName(isLegalName: boolean) {
		this.radioClicked = true;
		this.companyNameIsLegalName = isLegalName;
		if (isLegalName) {
			this.form.get(this.LEGAL_NAME)?.setValue(this.companyProfile?.legalBusinessName);
		} else {
			this.form.get(this.LEGAL_NAME)?.setValue(null);
		}
	}

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		if (!this.companyNameIsLegalName) {
			updatedCompanyProfile.legalBusinessName = this.form.get(this.LEGAL_NAME)?.value;
		}
		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	isInvalid() {
		return !this.radioClicked || this.form.invalid;
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Your company name", TextStyle.HEADLINE_LARGE)],
					["p1", new DisplayConfig(`Is your legal company name ${this.COMPANY_NAME_TOKEN}?`, TextStyle.TITLE_MEDIUM)],
					[
						"p2",
						new DisplayConfig(
							"Your legal company name is the one you used when you incorporated your business.",
							TextStyle.BODY_LARGE
						)
					],
					["yes-label", new DisplayConfig("Yes", TextStyle.LABEL_EXTRA_LARGE)],
					["no-label", new DisplayConfig("No", TextStyle.LABEL_EXTRA_LARGE)]
				])
			)
		],
		[
			this.LEGAL_NAME,
			new Layout(
				new Map<string, DisplayConfig>([
					["q1", new DisplayConfig("What is your company’s registered name?", TextStyle.TITLE_MEDIUM)],
					["q1-p1", new DisplayConfig("As shown in your article of incorporation documents.", TextStyle.BODY_LARGE)],
					["label", new DisplayConfig("Legal Company Name")]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(new Map<string, DisplayConfig>([["next", new DisplayConfig("Next", TextStyle.BUTTON_LARGE)]]))
		]
	]);
}
