<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()"> 
  <div>   
    <form class="d-flex flex-column ls-mt-2" [formGroup]="noticeOfAssignmentForm">
      <div class="ls-mb-11">
        <p class="h2 ls-mb-3">Would you prefer that we notify your customer to pay us directly in exchange for
          a lower discount rate?</p>
        <p class="l ls-mb-3 text-wrap">Disclosure of Accounts Receivable Financing relationships to your customers
          is a common practice, but it is not mandatory. At Raistone, we prioritize your business's unique needs
          and goals, offering flexible options tailored to suit your financial strategy.</p>
        <p class="l ls-mb-3 text-wrap">The manner in which you choose to communicate the financing relationship and
          manage collections can influence both the pricing and the structure of the financing we provide. Our
          customer support team is available to answer any questions you may have.</p>
        <div class="ls-mb-3">
          <ls-accordion header="Benefits of disclosing to your customer" [expanded]="false">
            <ul class="no-indent-list">
              <li>
                <p class="l ls-mb-2">
                  Less resources and bandwidth needed by you and your company.
                </p>
              </li>
              <li>
                <p class="l">
                  We can contact and collect on your behalf, resulting in optimal line efficiency.
                </p>
              </li>
            </ul>
          </ls-accordion>
        </div>
        <div *ngFor="let fd of finyn" class="ls-pb-3 d-flex align-items-center">
          <input mdbRadio [formControlName]="FINANCE_DISCLOSURE" class="radio" type="radio"
            name="financeDisclosure" [id]="'fd'+fd.id" [value]="fd.id" />
          <label class="xl" [for]="'fd'+fd.id">
            {{ fd.name }}
  
  
            <ls-tooltip *ngIf="fd.id === YesNo.YES" [position]="'right'" class="ls-ml-3">
              <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
              <div content>
                <p class="m ls-mb-0 text-wrap">If you decide to notify your customer to pay Raistone
                  directly, we'll provide them with a Notice of Assignment (NOA) to sign and return. We
                  can manage the notification and do all the collections without your intervention. You
                  can receive more favorable pricing if you notify your customer to pay Raistone.</p>
              </div>
            </ls-tooltip>
            <ls-tooltip *ngIf="fd.id === YesNo.NO" [position]="'right'" class="ls-ml-3">
              <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
              <div content>
                <p class="m ls-mb-0 text-wrap">If you decide to continue to collect payment from your
                  customer, you'll get less favorable pricing. You wouldn't disclose this financing to
                  your customer.</p>
              </div>
            </ls-tooltip>
          </label>
        </div>
      </div>
      <div *ngIf="selectedFinanceDisclosure === YesNo.NO">
        <p class="h2 ls-mb-3">Going forward, how would you like your customer to pay?</p>
        <p class="l ls-mb-3 text-wrap">We can manage the notifications and do all the collections without your
          intervention.</p>  
        <div *ngFor="let cp of collections" class="ls-pb-3 d-flex align-items-center">
          <input mdbRadio [formControlName]="COLLECTION_PREFERENCE" class="radio" type="radio"
            name="collectionPreference" [id]="'cp'+cp.id" [value]="cp.id" />
          <label class="xl" [for]="'cp'+cp.id">
            {{ cp.name }}
  
          </label>
        </div>
      </div>
    </form>
  </div>
</ls-questionnaire> 
