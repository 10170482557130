<ls-text extraClasses="ls-mb-4" [displayConfig]="config(HEADER,'hl1')"></ls-text>
<ls-text extraClasses="ls-mb-4" [displayConfig]="config(HEADER,'p1')"></ls-text>
<ls-text extraClasses="ls-mb-11" [displayConfig]="config(HEADER,'p2')"></ls-text>
<div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="onClick(confirmIndex)" 
    class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0">
        <ls-text [displayConfig]="config(BUTTONS,'confirm')"></ls-text>
</button>
    <button type="button" (click)="onClick(changeIndex)" 
    class="btn btn-lg  link-primary">
    <ls-text [displayConfig]="config(BUTTONS,'change')"></ls-text>
</button>
</div>